import { call, put, takeEvery, takeLatest, fork } from "redux-saga/effects"

// Crypto Redux States
import { ADD_EVENT_ANSWER, ADD_EVENT_ANSWER_CHECKLIST, ADD_EVENT_ANSWER_REGULAR, ADD_EVENT_COMPLAINT, GET_COMPLAINT_DEVICES, GET_COMPLAINT_PARENT_COMPANY, GET_COMPLAINT_USER, GET_EVENT_ANSWER, GET_PARENT_COMPANY_QUESTION_DATA, UPDATE_EVENT_COMPLAINT } from "./actionTypes"

import { getComplaintDevicesFail, getComplaintDevicesSuccess, getComplaintParentCompanyFail, getComplaintParentCompanySuccess, getComplaintUserFail, getComplaintUserSuccess, getParentCompanyQuestionDataFail, getParentCompanyQuestionDataSuccess, setComplaintModuleData, setLoadingIndicatorComplaint,
  addEventAnswerChecklistFail,
  addEventAnswerChecklistSuccess,
  addEventAnswerFail,
  addEventAnswerRegularFail,
  addEventAnswerRegularSuccess,
  addEventAnswerSuccess,
  addEventComplaintSuccess,
  addEventComplaintFail,
  getEventAnswerSuccess,
  getEventAnswerFail,
  updateEventComplaintSuccess,
  updateEventComplaintFail,
} from "./actions"

//Include Both Helper File with needed methods
import { 
  getComplaintUserList,
  getComplaintParentCompanyList,
  getComplaintDevicesList,
  getParentCompanyQuestionList,

  addEventAnswerData,
  addEventAnswerChecklistData,
  addEventAnswerItemChecklistData,
  addEventComplaint,
  getEventAnswersApi,
  sendComplaintMailerApi,
  updateEventComplaintApi,
  sendComplaintMailerNonRegApi,
  sendComplaintMailerAckApi
 } from "../../helpers/backend_helper"
import moment from "moment"
import { authUser } from "helpers/dataConfig"
import { Auth } from 'aws-amplify';

function* fetchComplaintUser() {

  yield put(setLoadingIndicatorComplaint(true));
  try {
    const data = {
      user_id : JSON.parse(localStorage.getItem(authUser)).fld_userid,
      corp_id : JSON.parse(localStorage.getItem(authUser)).fld_companyid
    }
    // console.log(data)
    const response = yield call(getComplaintUserList, data)
    // console.log(response.data)
    yield put(getComplaintUserSuccess(response.data))
} catch (error) {
    // console.log(error)
    yield put(getComplaintUserFail(error))
}
 
}

function* fetchComplaintParentCompanyData({payload : search_text}) {

  // console.log(search_text)
  yield put(setLoadingIndicatorComplaint(true));
  try {
    const data ={
      search_text : search_text.toLowerCase().trim()
    }
    
    const response = yield call(getComplaintParentCompanyList,data)
    // console.log(response.data)
    yield put(getComplaintParentCompanySuccess(response.data))
} catch (error) {
    // console.log(error)
    yield put(getComplaintParentCompanyFail(error))
}
 
}

function* fetchComplaintDevicesData({payload : search_text}) {

  // console.log(search_text)
  yield put(setLoadingIndicatorComplaint(true));
  try {
    const data ={
      search_text : search_text.toLowerCase().trim()
    }
    
    const response = yield call(getComplaintDevicesList,data)
    // console.log(response.data)
    yield put(getComplaintDevicesSuccess(response.data))
} catch (error) {
    // console.log(error)
    yield put(getComplaintDevicesFail(error))
}
 
}

function* fetchParentCompanyQuestionData({payload : data}) {

  // console.log(data)
  yield put(setLoadingIndicatorComplaint(true));
  try {
    // const data ={
    //   parent_company : parent_company.toLowerCase().trim()
    // }
    
    const response = yield call(getParentCompanyQuestionList,data)
    // console.log(response.module)
    yield put(getParentCompanyQuestionDataSuccess(response.data))
    yield put(setComplaintModuleData(JSON.parse(response.module)))
} catch (error) {
    // console.log(error)
    yield put(getParentCompanyQuestionDataFail(error))
}
 
}




function* onAddEventAnswerDetails({ payload: {answer} }) {

  // console.log(answer)
let answerObj = {
    answer_uid : answer.answer_uid,
    created_date : moment().format(),
    modified_date : moment().format(),
    timestamp : moment().format(),
    eventid : answer.eventid,
    answer_text : answer.answer_text,
    comments : answer.ans_comments,
    next_question : '',
    prev_question : '',
    question_id : answer.question_id
}
  yield put(setLoadingIndicatorComplaint(true));

  try {
      const response = yield call(addEventAnswerData, answerObj)
      // console.log(response)
      console.log('in done')
      yield put(addEventAnswerSuccess(response.data))
     

  } catch (error) {
    // console.log(error.message)
      yield put(addEventAnswerFail(error.message))
  }
}


function* onAddEventAnswerChecklistDetails({ payload: {answer} }) {

  // console.log(answer)
  let count = 0
  let count2 = 0

  let answerObj = ''
  let answerItemObj = ''

  yield put(setLoadingIndicatorComplaint(true));
  for(let i=0;i<answer.currentQuestion.length;i++){
    count2 = 0
    count++
    answerObj = {
          answer_uid : answer.currentQuestion[i].answer_uid,
          created_date : moment().format(),
          modified_date : moment().format(),
          timestamp : moment().format(),
          eventid : answer.eventid,
          answer_text : answer.currentQuestion[i].answer_text,
          comments : answer.currentQuestion[i].ans_comments,
          next_question : '',
          prev_question : '',
          question_id : answer.currentQuestion[i].question_id,
          // choice_data : answer.currentQuestion[i].choice_data
      }

        try {
      const response = yield call(addEventAnswerChecklistData, answerObj)
      // console.log(response)
      for(let j=0;j<answer.currentQuestion[i].choice_data.length;j++)
        {
          if(answer.currentQuestion[i].choice_data[j].ans_item_comments != '' || answer.currentQuestion[i].choice_data[j].ans_item_text != ''){
          answerItemObj = {
            // choice_data : answer.currentQuestion[i].choice_data[j]
            created_date : moment().format(),
            modified_date : moment().format(),
            timestamp : moment().format(),
             answerid : answer.currentQuestion[i].answer_uid,
             comments : answer.currentQuestion[i].choice_data[j].ans_item_comments,
             answer_text : answer.currentQuestion[i].choice_data[j].ans_item_text,
             form_item_external_id : '',
             form_id : answer.currentQuestion[i].choice_data[j].choice_id,
             prev_question : '',
             related_case : '',
              eventid : answer.eventid,
              score : 0,
               status : ''
          }
          try {
            const response2 = yield call(addEventAnswerItemChecklistData, answerItemObj)
            count2++
            if(count == answer.currentQuestion.length && count2 == answer.currentQuestion[i].choice_data.length){
              console.log('in done')
        yield put(addEventAnswerChecklistSuccess(response.data))
      }
          } catch (error2) {
          }
        }else{
          count2++
            if(count == answer.currentQuestion.length && count2 == answer.currentQuestion[i].choice_data.length){
              console.log('in done')
        yield put(addEventAnswerChecklistSuccess(response.data))
      }
        }
        }
    
      // if(count == answer.currentQuestion.length){
      //   yield put(addEventAnswerChecklistSuccess(response.data))
      // }
      // yield put(addEventAnswerSuccess(response.data))
     

  } catch (error) {
    count++
    if(count == answer.currentQuestion.length){
      yield put(addEventAnswerChecklistFail(response.data))
    }
    // console.log(error.message)
      // yield put(addEventAnswerFail(error.message))
  }
  }
// let answerObj = {
//     answer_uid : answer.answer_uid,
//     created_date : moment().format(),
//     modified_date : moment().format(),
//     timestamp : moment().format(),
//     eventid : answer.eventid,
//     answer_text : answer.answer_text,
//     comments : answer.ans_comments,
//     next_question : '',
//     prev_question : '',
//     question_id : answer.question_id
// }
//   yield put(setLoadingIndicatorDTSF(true));

//   try {
//       const response = yield call(addEventAnswerData, answerObj)
//       // console.log(response)
//       yield put(addEventAnswerSuccess(response.data))
     

//   } catch (error) {
//     // console.log(error.message)
//       yield put(addEventAnswerFail(error.message))
//   }
}


function* onAddEventAnswerRegularDetails({ payload: {answer} }) {

  // console.log(answer)
  let count = 0

  let answerObj = ''
  let answerItemObj = ''

  yield put(setLoadingIndicatorComplaint(true));

    count++
    answerObj = {
          answer_uid : answer.answer_uid,
          created_date : moment().format(),
          modified_date : moment().format(),
          timestamp : moment().format(),
          eventid : answer.eventid,
          answer_text : answer.answer_text,
          comments : answer.ans_comments,
          next_question : '',
          prev_question : '',
          question_id : answer.question_id,
          // choice_data : answer.currentQuestion[i].choice_data
      }

        try {
      const response = yield call(addEventAnswerChecklistData, answerObj)
      // console.log(response)
      for(let j=0;j<answer.choice_data.length;j++)
        {
          if(answer.choice_data[j].ans_item_comments != '' || answer.choice_data[j].ans_item_text != ''){
          answerItemObj = {
            // choice_data : answer.currentQuestion[i].choice_data[j]
            created_date : moment().format(),
            modified_date : moment().format(),
            timestamp : moment().format(),
             answerid : answer.answer_uid,
             comments : answer.choice_data[j].ans_item_comments,
             answer_text : answer.choice_data[j].ans_item_text,
             form_item_external_id : '',
             form_id : answer.choice_data[j].choice_id,
             prev_question : '',
             related_case : '',
              eventid : answer.eventid,
              score : 0,
               status : ''
          }
          try {
            const response2 = yield call(addEventAnswerItemChecklistData, answerItemObj)
            count++
            if(count == answer.choice_data.length){
              console.log('in done')
        yield put(addEventAnswerRegularSuccess(response.data))
      }
          } catch (error2) {
          }
        }else{
          count++
            if(count == answer.choice_data.length){
              console.log('in done')
        yield put(addEventAnswerRegularSuccess(response.data))
      }
        }
        }
    
      // if(count == answer.currentQuestion.length){
      //   yield put(addEventAnswerChecklistSuccess(response.data))
      // }
      // yield put(addEventAnswerSuccess(response.data))
     

  } catch (error) {
    count++
    if(count == answer.currentQuestion.length){
      yield put(addEventAnswerRegularFail(response.data))
    }
    // console.log(error.message)
      // yield put(addEventAnswerFail(error.message))
  }
  

}


function* addEventComplaintData({payload :{ data,history, ans_dt, comp_reg}}) {

  // console.log(ans_dt)
  yield put(setLoadingIndicatorComplaint(true));
  try {
    const additional = {
      status : 'Active',
      approval_status : 'Pending',
      lastupdatedon :  moment().format(),
      lastupdatedby : JSON.parse(localStorage.getItem(authUser)).fld_userid,
      createdon :  moment().format(),
      createdby : JSON.parse(localStorage.getItem(authUser)).fld_userid,
  }
  const compData = {...data, ...additional}
    
    const response = yield call(addEventComplaint,compData)
    // console.log(response.data)
    // yield put(addEventComplaintSuccess(response.data))


    const processedQuestions = [];

// Iterate through each question and process based on content_item_type
ans_dt.forEach(question => {
  if (question.content_item_type !== 'Form') {
    // If not 'Form', add the question as is with sort_no as current index of processedQuestions
    processedQuestions.push({
      ...question, // Keep the original question data
      sort_no: processedQuestions.length + 1 // Use the current index of processedQuestions as sort_no
    });
  } else {
    // If 'Form', add each choice as a separate question with sort_no as current index of processedQuestions
    question.choice_data.forEach(choice => {
      processedQuestions.push({
        question_id: choice.choice_id,    // Use choice_id for uniqueness
        name: choice.choice_name,         // Use choice name
        question_text: choice.choice_text, // Set choice text as question text
        element: choice.element,          // Same element as the choice
        content_item_type: question.content_item_type, // Keep the content type
        answer_text: choice.ans_item_text, // Set answer text from choice
        field_type: choice.field_type,     // Set the field type
        isEmpty: choice.isEmpty,           // Additional choice-related info
        sort_no: processedQuestions.length + 1 // Use the current index of processedQuestions as sort_no
      });
    });
  }
});

    const comp_details = {
      complaint_no : response.data,
      complaint_date : data.complaint_date,
      device_name : data.device_name,
      reason : data.title,
      question_data : processedQuestions,
     
    }

    if(comp_reg == null){
      yield fork(sendComplaintMailerNonReg, {...comp_details,email : 'sumesh.velayudhan@smarteeva.com'})
    }else{
    yield fork(sendComplaintMailer, {...comp_details,email : 'sumesh.velayudhan@smarteeva.com'})
    }

    yield fork(sendComplaintAckMailer, {...comp_details,email : JSON.parse(localStorage.getItem(authUser)).fld_email,username : JSON.parse(localStorage.getItem(authUser)).fld_username})
    
    try {
      yield call(fetchComplaintUser)
      history('/complaints')
  } catch (error) {
      yield put(addEventComplaintFail(error))
  }
  
} catch (error) {
    // console.log(error)
    yield put(addEventComplaintFail(error))
}
 
}


// Define the background task
function* sendComplaintMailer(ans_dt) {

  // let userData = {
  //   question_data : ans_dt,
  //   email : 'sumesh.velayudhan@smarteeva.com'
  // }

  try {
    // Call your API here with the required user details
    yield call(sendComplaintMailerApi, ans_dt);
    console.log('Background API call completed');
  } catch (error) {
    console.log('Background API error:', error);
  }
}

// Define the background task
function* sendComplaintMailerNonReg(ans_dt) {

  // let userData = {
  //   question_data : ans_dt,
  //   email : 'sumesh.velayudhan@smarteeva.com'
  // }

  try {
    // Call your API here with the required user details
    yield call(sendComplaintMailerNonRegApi, ans_dt);
    console.log('Background API call completed');
  } catch (error) {
    console.log('Background API error:', error);
  }
}

// Define the background task
function* sendComplaintAckMailer(ans_dt) {

  // let userData = {
  //   question_data : ans_dt,
  //   email : (JSON.parse(localStorage.getItem(authUser))).fld_email
  // }

  try {
    // Call your API here with the required user details
    yield call(sendComplaintMailerAckApi, ans_dt);
    console.log('Background API call completed');
  } catch (error) {
    console.log('Background API error:', error);
  }
}

function*  getEventAnswerData({payload : data}) {

  console.log(data)
  yield put(setLoadingIndicatorComplaint(true));
  try {
    
    const response = yield call(getEventAnswersApi,data)
    console.log(response.data)
    yield put(getEventAnswerSuccess(response.data))
  
} catch (error) {
    // console.log(error)
    yield put(getEventAnswerFail(error))
}
 
}


function*  updateEventComplaintData({payload : {data, history, ans_dt,comp_reg, comp_no}}) {

  // console.log(data)
  yield put(setLoadingIndicatorComplaint(true));
  try {
    
    const additional = {
      lastupdatedon :  moment().format(),
      lastupdatedby : JSON.parse(localStorage.getItem(authUser)).fld_userid,
  }
  const compData = {...data, ...additional}

    const response = yield call(updateEventComplaintApi,compData)
    // console.log(response.data)
    
    const processedQuestions = [];

// Iterate through each question and process based on content_item_type
ans_dt.forEach(question => {
  if (question.content_item_type !== 'Form') {
    // If not 'Form', add the question as is with sort_no as current index of processedQuestions
    processedQuestions.push({
      ...question, // Keep the original question data
      sort_no: processedQuestions.length + 1 // Use the current index of processedQuestions as sort_no
    });
  } else {
    // If 'Form', add each choice as a separate question with sort_no as current index of processedQuestions
    question.choice_data.forEach(choice => {
      processedQuestions.push({
        question_id: choice.choice_id,    // Use choice_id for uniqueness
        name: choice.choice_name,         // Use choice name
        question_text: choice.choice_text, // Set choice text as question text
        element: choice.element,          // Same element as the choice
        content_item_type: question.content_item_type, // Keep the content type
        answer_text: choice.ans_item_text, // Set answer text from choice
        field_type: choice.field_type,     // Set the field type
        isEmpty: choice.isEmpty,           // Additional choice-related info
        sort_no: processedQuestions.length + 1 // Use the current index of processedQuestions as sort_no
      });
    });
  }
});

    const comp_details = {
      complaint_no : comp_no,
      complaint_date : data.complaint_date,
      device_name : data.device_name,
      reason : data.title,
      question_data : processedQuestions
    }


    if(comp_reg == null){
      yield fork(sendComplaintMailerNonReg, {...comp_details,email : 'sumesh.velayudhan@smarteeva.com'})
    }else{
    yield fork(sendComplaintMailer, {...comp_details,email : 'sumesh.velayudhan@smarteeva.com'})
    }
    yield fork(sendComplaintAckMailer,  {...comp_details,email : JSON.parse(localStorage.getItem(authUser)).fld_email,username : JSON.parse(localStorage.getItem(authUser)).fld_username})
    
    // yield put(updateEventComplaintSuccess(response.data))

    try {
      yield call(fetchComplaintUser)
      history('/complaints')
  } catch (error) {
      yield put(addEventComplaintFail(error))
  }
  
  
} catch (error) {
    // console.log(error)
    yield put(updateEventComplaintFail(error))
}
 
}

function* complaintsSaga() {
  yield takeEvery(GET_COMPLAINT_USER, fetchComplaintUser)
  yield takeLatest(GET_COMPLAINT_PARENT_COMPANY, fetchComplaintParentCompanyData)
  yield takeLatest(GET_COMPLAINT_DEVICES, fetchComplaintDevicesData)
  yield takeEvery(GET_PARENT_COMPANY_QUESTION_DATA, fetchParentCompanyQuestionData)

  yield takeEvery(ADD_EVENT_ANSWER, onAddEventAnswerDetails)
  yield takeEvery(ADD_EVENT_ANSWER_CHECKLIST, onAddEventAnswerChecklistDetails)
  yield takeEvery(ADD_EVENT_ANSWER_REGULAR, onAddEventAnswerRegularDetails)

  yield takeEvery(ADD_EVENT_COMPLAINT, addEventComplaintData)
  yield takeEvery(GET_EVENT_ANSWER, getEventAnswerData)

  yield takeEvery(UPDATE_EVENT_COMPLAINT, updateEventComplaintData)



}

export default complaintsSaga;
