import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// users
import user1 from "../../../assets/images/users/user_icon.png";
import { authUser } from "helpers/dataConfig";
import { useNavigate} from "react-router-dom"


const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const [username, setusername] = useState("");
  const [userPhoto, setUserPhoto] = useState(null);

  const navigate = useNavigate()

  useEffect(() => {
    if (localStorage.getItem(authUser)) {
      // if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      //   const obj = JSON.parse(localStorage.getItem("authUser"));
      //   setusername(obj.displayName);
      // } else if (
      //   process.env.REACT_APP_DEFAULTAUTH === "fake" ||
      //   process.env.REACT_APP_DEFAULTAUTH === "jwt"
      // ) {
        const obj = JSON.parse(localStorage.getItem(authUser));
        setusername(obj.username);
        setUserPhoto(obj.fld_photo)
        
      // }
    }
  }, [props.success]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={userPhoto == null || userPhoto == '' ? user1 : userPhoto}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" 
            onClick={() => {
              navigate('/profile')
          }}
          >
            {" "}
            <i className="bx bx-user font-19 align-middle me-2" />
            {props.t("Profile")}{" "}
          </DropdownItem>
        
          <div className="dropdown-divider" />

          <DropdownItem tag="a"
           onClick={() => {
            navigate('/change-password')
        }}
          >
            {/* <span className="badge bg-success float-end">11</span> */}
            <i className="bx bx-lock-open font-19 align-middle me-2" />
            {props.t("Change Password")}
          </DropdownItem>
          {/* <DropdownItem tag="a" href="/my-data">
            <i className="bx bx-stats font-19 align-middle me-2" />
            {props.t("My Submissions")}
          </DropdownItem> */}
          {/* <DropdownItem tag="a" href="/auth-lock-screen">
            <i className="bx bx-lock-open font-19 align-middle me-2" />
            {props.t("Lock Screen")}
          </DropdownItem> */}
          {/* <DropdownItem tag="a" href="/access-setup">
            <i className="bx bx-lock-open font-19 align-middle me-2" />
            {props.t("Access Setup")}
          </DropdownItem> */}

          {/* <DropdownItem tag="a" href="/support">
            <i className="bx bx-support font-19 align-middle me-2" />
            {props.t("Contact Support")}
          </DropdownItem> */}
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-19 align-middle me-2 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
