import React, { useEffect, useState } from 'react';
import { Card, CardHeader, Button, CardBody, Collapse, Input, Col, InputGroup, Label } from 'reactstrap';
import Select from 'react-select';
import Flatpickr from "react-flatpickr";
import Dropzone from "react-dropzone";
import { useSelector, useDispatch } from "react-redux";
import { addEventAnswer, addEventAnswerRegular, saveMailerAnswers } from 'store/actions';
import { image_upload } from 'helpers/dataConfig';
import axios from 'axios';
import Notiflix from 'notiflix';
import moment from 'moment';

const Chatbot = ({ questions, eventData, ansData, type }) => {


  const dispatch = useDispatch();

  const [activeIndex, setActiveIndex] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState([questions[0]]);
  const [questionsRef, setQuestionsRef] = useState(questions);
  const [commentsVisible, setCommentsVisible] = useState(false);
  const [currentChoice, setCurrentChoice] = useState(null);
  const [viewQuestionData, setViewQuestionData] = useState([]);

  const [answerText, setAnswerText] = useState('');
  const [commentsText, setCommentsText] = useState('');
  const [answerQuestion, setAnswerQuestion] = useState('');
  const [finalAnswer, setFinalAnswer] = useState(null);



  const toggle = (index, choice) => {
    // setActiveIndex(activeIndex === index ? null : index);
    // setCurrentQuestion(questions[index])
    // console.log(index == -1)

    if (index == -1) {
      // setCurrentQuestion(null)
      setCurrentChoice(null)
      setCommentsVisible(false)
    } else {
      setCurrentChoice(null)
      setCommentsVisible(false)
      setCurrentQuestion(questionsRef[index])
      // currentChoice(choice)
      // commentsVisible(true)

      // showComments(questions[index],choice)

    }
  };

  const showComments = (ques, choice) => {
    console.log(questionsRef)
    if (choice.display_comments) {
      setCurrentChoice(choice)
      setCommentsVisible(true)
    } else {
      setCurrentChoice(null)
      setCommentsVisible(false)
      const nextQuestionIndex = questionsRef.findIndex(q => q.question_id === ques.next_question);
      toggle(nextQuestionIndex, choice);
    }

  }

  useEffect(() => {
    setCurrentQuestion([questions[0]])
    setViewQuestionData(questions)
  }, [questions])


  // Function to update the answer for a specific question
  const updateQuestionAnswer = (question, choice, answer) => {
    let updatedIndex = 0
    let nextQuestionIndex = 0
    if (question.content_item_type == 'Branching') {
      nextQuestionIndex = questionsRef.findIndex(q => q.question_id === choice.next_question);
    }
    else {
      nextQuestionIndex = questionsRef.findIndex(q => q.question_id === question.next_content_item);
    }
    const updatedQuestions = currentQuestion.map((q, indx) => {
      if (q.question_id === question.question_id) {
        updatedIndex = indx
        return { ...q, answer_text: answer, ans_comments: commentsText }; // Assuming you want to store the answer directly in the question object
      }
      return q;
    });

    dispatch(addEventAnswer({ ...updatedQuestions[updatedIndex], eventid: eventData.EVENT_ID }))
    setAnswerText('')
    setCommentsText('')
    setAnswerQuestion('')
    setCurrentChoice('')

    // setCurrentQuestion(nextQuestionIndex);
    if (nextQuestionIndex == -1) {

      let quesMainData = [...viewQuestionData]      
      quesMainData[quesMainData.findIndex(val => val.question_id == question.question_id)] = updatedQuestions[updatedIndex]
      setViewQuestionData(quesMainData)
      dispatch(saveMailerAnswers(quesMainData))

      setFinalAnswer(currentQuestion)
      // setCurrentQuestion(null)
    } else {

      setCurrentQuestion([...updatedQuestions, questionsRef[nextQuestionIndex]])


      let quesMainData = [...viewQuestionData]      
      quesMainData[quesMainData.findIndex(val => val.question_id == question.question_id)] = updatedQuestions[updatedIndex]
      setViewQuestionData(quesMainData)
      dispatch(saveMailerAnswers(quesMainData))
    }



  };

  const updateQuestionItemAnswer = (question, choice, answerText) => {

    // console.log(viewQuestionData)
    const updatedQuestion = currentQuestion.map((q) => {
      if (q.question_id == question.question_id) {
        const updatedChoices = q.choice_data.map((c) => {
          if (c.choice_id == choice.choice_id) {
            return { ...c, ans_item_text: answerText };
          }
          return c;
        });
        return { ...q, choice_data: updatedChoices };
      }
      return q;
    });
    // const updatedChoices = question.choice_data.map((c) => {
    //   if (c.choice_id === choice.choice_id) {
    //     return { ...c, ans_item_text: answerText };
    //   }
    //   return c;
    // });

    // const updatedQuestion = { ...question, choice_data: updatedChoices };

    // let quesMainData = [...viewQuestionData]  
    let quesMainData = []      
    quesMainData = updatedQuestion
    // quesMainData[quesMainData.findIndex(val => val.question_id == question.question_id)] = updatedQuestion
    // console.log(quesMainData)
    setViewQuestionData(quesMainData)
    dispatch(saveMailerAnswers(quesMainData))

    setCurrentQuestion(updatedQuestion);
  }

  // Function to update the answer for a specific question
  const updateQuestionItemComments = (question, choice, commentsText) => {


    const updatedQuestion = currentQuestion.map((q) => {
      if (q.question_id == question.question_id) {
        const updatedChoices = q.choice_data.map((c) => {
          if (c.choice_id == choice.choice_id) {
            return { ...c, ans_item_comments: commentsText };
          }
          return c;
        });
        return { ...q, choice_data: updatedChoices };
      }
      return q;
    });
    // const updatedChoices = question.choice_data.map((c) => {
    //   if (c.choice_id === choice.choice_id) {
    //     return { ...c, ans_item_comments: commentsText };
    //   }
    //   return c;
    // });

    // const updatedQuestion = { ...question, choice_data: updatedChoices };

    setCurrentQuestion(updatedQuestion);

    let quesMainData = [...viewQuestionData]      
    quesMainData[quesMainData.findIndex(val => val.question_id == question.question_id)] = updatedQuestion
    setViewQuestionData(quesMainData)
    dispatch(saveMailerAnswers(quesMainData))
  }

  // Function to render different question types
  const renderQuestionType = (question) => {
    switch (question.content_item_type) {
      case "Branching":
        return <div>
          {question.choice_data.map((choice) => (
            <div key={choice.choice_id}>
              <button style={{ width: '100%' , marginBottom : 20}} className={
                (answerText == choice.choice_text && answerQuestion == question) ||
                  question.answer_text == choice.choice_text ? 'btn btn-primary' : 'btn btn-outline-primary'} onClick={() => {

                    // updateQuestionAnswer(question, choice.choice_text); 
                    setAnswerText(choice.choice_text)
                    setAnswerQuestion(question)
                    setCurrentChoice(choice)

                    if (!choice.display_comments) {
                      updateQuestionAnswer(question, choice, choice.choice_text)
                    }


                    const nextQuestionIndex = questionsRef.findIndex(q => q.question_id === choice.next_question);
                    // toggle(nextQuestionIndex, choice);
                    // showComments(question, choice)
                  }}>
                {choice.choice_text}

              </button>


            </div>
          ))}
        </div>
      case "Form":
        return <div style={{ padding: 20, display: 'grid' }}>
          {question.choice_data.map((choice, ch_index) => (
            <div key={ch_index}>
              {renderFormType(choice, question)}

            </div>

          ))}
          <button style={{ margin: 'auto' }} className='btn btn-primary saveButton' onClick={() => {
            // const nextQuestionIndex = questionsRef.findIndex(q => q.question_id === question.next_question);
            // // toggle(nextQuestionIndex);
            // if(nextQuestionIndex == -1){
            //   setCurrentQuestion(null)
            // }else{
            //   setCurrentQuestion(questionsRef[index])

            // }

            const nextQuestionIndex = questionsRef.findIndex(q => q.question_id == question.next_content_item);

            // console.log(questionsRef)
            // console.log(question)

            let empty_ques = question.choice_data.filter(q => q.ans_item_text == '')

            //  console.log(currentQuestion[currentQuestion.findIndex(val => val.question_id == question.question_id)])

            // Find the index of the question to update
              const index = currentQuestion.findIndex(val => val.question_id === question.question_id);

              // Update the state at the found index
             
           
            if (empty_ques.length <= 0) {

              if (nextQuestionIndex == -1) {

                // console.log(child_ans)
                let child_new = questions[questions.findIndex(q => q.question_id === question.question_id)]
                dispatch(addEventAnswerRegular({ ...child_new, eventid: eventData.EVENT_ID }))

                // setCurrentQuestion(null)
                

                // setChildQuestions(null)

                if (index !== -1) {
                  const updatedQuestions = [
                    ...currentQuestion.slice(0, index),
                    { ...currentQuestion[index], data_saved: true  }, // Update the specific field here
                    ...currentQuestion.slice(index + 1)
                  ];
  
                  // Now set the state with the updated array
                  setCurrentQuestion(updatedQuestions); // Assuming setCurrentQuestion is the state setter
                }


              } else {
                let child_new = questions[questions.findIndex(q => q.question_id === question.question_id)]
                dispatch(addEventAnswerRegular({ ...child_new, eventid: eventData.EVENT_ID }))

                // setCurrentQuestion(questionsRef[index])
                // setCurrentQuestion([...currentQuestion, questionsRef[nextQuestionIndex]])

      
                    if (index !== -1) {
                      const updatedQuestions = [
                        ...currentQuestion.slice(0, index),
                        { ...currentQuestion[index], data_saved: true }, // Update the specific field here
                        ...currentQuestion.slice(index + 1)
                      ];
                      
                      // Set the state with the updated array
                      setCurrentQuestion([...updatedQuestions, questionsRef[nextQuestionIndex]]);
                    } else {
                      // If the question does not exist, add the new question to the state
                      setCurrentQuestion([...currentQuestion, questionsRef[nextQuestionIndex]]);
                    }

              }

            } else {
              Notiflix.Notify.failure('Please fill all details')
            }
          }}>
            <i className="bx bx-save"></i> Save

          </button>
        </div>; // Simplified
      case "Choices Multiple":
        return <div style={{ padding: 20 }}>
          {MultipleTextView(question)}
          <button style={{ margin: 'auto' }} className='btn saveButton' onClick={() => {

            setAnswerQuestion(question)
            //   setCurrentChoice(choice)
            if (!currentChoice.display_comments) {
              updateQuestionAnswer(question, currentChoice, answerText)
            }
          }}>
            <i className="bx bx-save"></i> Save

          </button>

        </div>
      case "Content Piece":
        return <div dangerouslySetInnerHTML={{ __html: question.question_sub_text }} />;
      case "Image Attachment":
        return <div>
          {question.choice_data.map((choice, ind) => (
            <Dropzone key={ind}
              onDrop={acceptedFiles => {
                // handleAcceptedFiles(acceptedFiles)
                //  console.log(acceptedFiles)
                const data = new FormData();
                data.append('file', acceptedFiles[0]);

                try {
                  const response = axios.post(image_upload, data, {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                  });
                  //    console.log('Response:', response.data);
                  updateQuestionAnswer(ques, choice, response.fileurl)
                } catch (error) {
                  console.error('Error uploading file:', error);
                }
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone">
                  <div
                    className="dz-message needsclick mt-2"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <div className="mb-3">
                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                    </div>
                    <h4>Drop images here or click to upload.</h4>
                  </div>
                </div>
              )}
            </Dropzone>
          ))}
        </div>
      case "Choices":
        return <div style={{ padding: 20 }}>
          {SingleSelectTextView(question)}

        </div>
      default:
        return null;
    }
  };

   // Function to render different question types
   const renderAnswerType = (question) => {
    // console.log(question)
    switch (question.content_item_type) {
      case "Branching":
        return  <div> {question.answer_text}</div>
      case "Form":
        return <div style={{ padding: 20, display: 'grid' }}>
          {question.choice_data.map((choice, ch_index) => (
            <div key={ch_index}>
              <h6>{choice.choice_text}</h6>
              <p>{choice.ans_item_text}</p>
            </div>

          ))}
          
        </div>; // Simplified
      case "Choices Multiple":
        return <div style={{ padding: 20 }}>
          
           <div> {question.answer_text}</div>

         
        </div>
      case "Content Piece":
        return <div dangerouslySetInnerHTML={{ __html: question.question_sub_text }} />;
      // case "Image Attachment":
      case "Image Attachment":
        return <div>
          {/* {question.choice_data.map((choice, ind) => ( */}
         
            <div >
            <img src={question.answer_text} />
            </div>
     
        </div>
      case "Choices":
        return <div style={{ padding: 20 }}>
          {/* {SingleSelectTextView(question)} */}
            {question.answer_text}
        </div>
      default:
        return null;
    }
  };



  const renderFormType = (choice, question) => {
    switch (choice.field_type) {
      case "Date":
        return <div className='row'>
          <div className={choice.display_comments ? 'col-lg-12' : 'col-lg-12'}><Label className='required'>{choice.choice_text}</Label >{DateTextView(question, choice)}
            <div className='col-lg-12'>
              <div className="input-wrapper mb-5" style={{ display: choice.display_comments ? '' : 'none' }}>
                <Label className={choice.comments_required ? "form-label required" : "form-label"} style={{ marginBottom: 0 }}>Add Comments</Label>
                <Input
                  name="key"
                  type="textarea"
                  rows={5}
                  value={choice.ans_item_comments}
                  onChange={(text) => {
                    updateQuestionItemComments(question, choice, text.target.value)
                  }}
                  placeholder="" />
              </div>
            </div>
            <hr
              style={{
                color: '#dcdcdc',
                backgroundColor: '#dcdcdc',
                height: 1
              }}
            /></div>

        </div>;
      case "Picklist":
        return <div className='row'><div className='col-lg-12'><Label className='required'>{choice.choice_text}</Label>{DropdownTextView(question, choice)}

          <div className='col-lg-12'>
            <div className="input-wrapper mb-5" style={{ display: choice.display_comments ? '' : 'none' }}>
              <Label className={choice.comments_required ? "form-label required" : "form-label"} style={{ marginBottom: 0 }}>Add Comments</Label>
              <Input
                name="key"
                type="textarea"
                rows={5}
                onChange={(text) => {
                  updateQuestionItemComments(question, choice, text.target.value)
                }}
                placeholder="" />
            </div>
          </div>
          <hr
            style={{
              color: '#dcdcdc',
              backgroundColor: '#dcdcdc',
              height: 1
            }}
          />
        </div>
        </div>;
      case "Text":
        return <div className='row'><div className={choice.display_comments ? 'col-lg-12' : 'col-lg-12'}><Label className="form-label required">{choice.choice_text}</Label>{ShortTextView(question, choice)}
        </div>
          <div className='col-lg-12'>
            <div className="input-wrapper mb-5" style={{ display: choice.display_comments ? '' : 'none' }}>
              <Label className={choice.comments_required ? "form-label required" : "form-label"}>Add Comments</Label>
              <Input
                name="key"
                type="textarea"
                rows={3}
                onChange={(text) => {
                  updateQuestionItemComments(question, choice, text.target.value)
                }}
                placeholder="" />
            </div>
          </div>
          <hr
            style={{
              color: '#dcdcdc',
              backgroundColor: '#dcdcdc',
              height: 1
            }}
          />


        </div>;
      case "Long Text":
        return <div className='row'><div className='col-lg-12'><Label className='required'>{choice.choice_text}</Label>{LongTextView(question, choice)}

          <div className='col-lg-12'>
            <div className="input-wrapper mb-5" style={{ display: choice.display_comments ? '' : 'none' }}>
              <Label className={choice.comments_required ? "form-label required" : "form-label"} style={{ marginBottom: 0 }}>Add Comments</Label>
              <Input
                name="key"
                type="textarea"
                rows={5}
                onChange={(text) => {
                  updateQuestionItemComments(question, choice, text.target.value)
                }}
                placeholder="" />
            </div>
          </div>
          <hr
            style={{
              color: '#dcdcdc',
              backgroundColor: '#dcdcdc',
              height: 1
            }}
          />
        </div>
        </div>;
      case "Number":
        return <div className='row'><div className='col-lg-6'><Label className='required'>{choice.choice_text}</Label>{NumberTextView(question, choice)}

          <div className='col-lg-12'>
            <div className="input-wrapper mb-5" style={{ display: choice.display_comments ? '' : 'none' }}>
              <Label className={choice.comments_required ? "form-label required" : "form-label"} style={{ marginBottom: 0 }}>Add Comments</Label>
              <Input
                name="key"
                type="textarea"
                rows={5}
                onChange={(text) => {
                  updateQuestionItemComments(question, choice, text.target.value)
                }}
                placeholder="" />
            </div>
          </div>
          <hr
            style={{
              color: '#dcdcdc',
              backgroundColor: '#dcdcdc',
              height: 1
            }}
          />
        </div>
        </div>;
      default:
        return null;
    }
  };

  const ShortTextView = (question, choice) => {
    return (
      <Col lg='12'>
        <div className=" mb-3">
          <Input
            //   disabled 
            name="key"
            type="textarea"
            rows={3}
            placeholder=""
            value={choice.ans_item_text}
            onChange={(e) => updateQuestionItemAnswer(question, choice, e.target.value)
            }
          />
        </div>
      </Col>
    )
  }


  const LongTextView = (question, choice) => {
    return (
      <Col lg='12'>
        <div className="mb-3">
          <Input
            //   disabled
            name="key"
            type="textarea"
            rows={5}
            placeholder=""
            value={choice.ans_item_text}
            onChange={(e) => updateQuestionItemAnswer(question, choice, e.target.value)
            }
          />
        </div>
      </Col>
    )
  }


  const NumberTextView = (question, choice) => {
    return (
      <Col lg='12'>
        <div className="mb-3">
          <InputGroup>
            <div
              className="input-group-append"
              onClick={() => {
                if (counter > 0) {
                  setCounter(counter - 1);
                  updateQuestionItemAnswer(question, choice, counter - 1)
                }
              }}
            >
              <Button type="button" color="primary">
                <i className="mdi mdi-minus" />
              </Button>
            </div>
            <Input
              style={{
                borderRight: 0,
                marginRight: -5,
                borderLeft: 0,
                marginLeft: -5,
                background: 'transparent',
                textAlign: 'center'
              }}

              type="number"
              // className="form-control"
              value={counter}
              onChange={(e) => {
                // if(e.target.value == ''){
                //   setCounter(0)
                // }
                setCounter(parseInt(e.target.value))
                updateQuestionItemAnswer(question, choice, parseInt(e.target.value))
                // }
              }}
            // placeholder="number"
            // readOnly

            />
            <div
              className="input-group-append"
              onClick={() => {

                setCounter(counter + 1);
                updateQuestionItemAnswer(question, choice, counter + 1)

              }}
            >
              <Button type="button" color="primary">
                <i className="mdi mdi-plus" />
              </Button>
            </div>
          </InputGroup>
        </div>
      </Col>
    )
  }


  const DateTextView = (question, choice) => {
    return (
      <Col lg='12'>
        <div className="mb-3">
          <Flatpickr

            className="form-control d-block"
            placeholder="Pick a date"
            options={{
              altInput: true,
              dateFormat: "d-m-y"
            }}
            value={Date.parse(choice.ans_item_text)}
            onChange={(e) => updateQuestionItemAnswer(question, choice, moment(new Date(e)).format('MM/DD/YYYY'))
            }
          />
        </div>
      </Col>
    )
  }


  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: 'white',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'white' : 'white', // Change background color when selected
      color: state.isSelected ? 'black' : 'black', // Change text color when selected
    }),
  };

  const DropdownTextView = (question, choice) => {

    return (
      <Col lg='12'>
        <div className="mb-3">


          <Select
            name={''}
            isClearable
            isSearchable
            options={choice.picklist_values.split(',').map((option, index) => ({
              label: option,
              value: option, // or any unique identifier for the value
            }))}
            value={{ label: choice.ans_item_text, value: choice.ans_item_text }}
            onChange={(e) => updateQuestionItemAnswer(question, choice, e.value)
            }
            styles={customStyles}

          />

        </div>
      </Col>
    )
  }


  const MultipleTextView = (ques) => {
    return (
      <Col lg='12'>
        <div className="mb-3" style={{ paddingLeft: 15, paddingRight: 15 }}>

          {ques.choice_data.map((choice, index) => (
            <div className="form-check form-checkbox-outline form-check-primary mb-4 col-lg-12" key={index}>
              <input
                style={{ color: '#232D42' }}
                type="checkbox"
                className="form-check-input"
                id="customCheck-outlinecolor1"
                //  checked={customOutlinePrimary}
                checked={answerText.split(',').includes(choice.choice_text)}
                //  onChange={(dt) => {
                //   let selectedChoices = answerText.split(',')
                //   if (selectedChoices.includes(choice.choice_text)) {
                //     selectedChoices = selectedChoices.filter(choice => choice !== choice.choice_text);
                //   } else {
                //     selectedChoices.push(choice.choice_text);
                //   }
                //   console.log(selectedChoices.join(', '))
                //   setAnswerText(selectedChoices.join(', '))
                //   // setAnswerText((prevAnswerText) => {
                //   //   let selectedChoices = prevAnswerText.split(',').filter(Boolean);
                //   //   if (selectedChoices.includes(choice.choice_text)) {
                //   //     selectedChoices = selectedChoices.filter(choice => choice !== choice.choice_text);
                //   //   } else {
                //   //     selectedChoices.push(choice.choice_text);
                //   //   }
                //   //   console.log(selectedChoices.join(', '))
                //   //   return selectedChoices.join(', ');
                //   // });

                //   setAnswerQuestion(ques)  
                //   setCurrentChoice(choice)
                //   if(!choice.display_comments){
                //     updateQuestionAnswer(ques, choice, choice.choice_text)
                //   }
                //  }}
                onClick={() => {
                  setAnswerText((prevAnswerText) => {
                    let selectedChoices = prevAnswerText.split(',').filter(Boolean);
                    if (selectedChoices.includes(choice.choice_text)) {
                      selectedChoices = selectedChoices.filter(choice => choice !== choice.choice_text);
                    } else {
                      selectedChoices.push(choice.choice_text);
                    }
                    console.log(selectedChoices.join(','))
                    return selectedChoices.join(',');
                  });

                  setAnswerQuestion(ques)
                  setCurrentChoice(choice)
                  if (!choice.display_comments) {
                    // updateQuestionAnswer(ques, choice, choice.choice_text)
                  }
                }}
              />
              <label
                style={{ color: '#232D42' }}
                className="form-check-label"
                htmlFor="customCheck-outlinecolor1"
              >
                {choice.choice_text}
              </label>
            </div>
          ))}


        </div>
      </Col>
    )
  }

  const SingleSelectTextView = (ques) => {
    return (
      <Col lg='12'>
        <div className="mb-3" style={{ paddingLeft: 15, paddingRight: 15 }}>

          {ques.choice_data.map((choice, index) => (
            <div className="form-check form-checkbox-outline form-check-primary mb-4 col-lg-12" key={index}>
              <input
                style={{ color: '#232D42' }}
                type="radio"
                className="form-check-input"
                id={`customRadio-outlinecolor1-${index}`}
                checked={answerText === choice.choice_text}
                onChange={() => {
                  setAnswerText(choice.choice_text);
                  setAnswerQuestion(ques)
                  setCurrentChoice(choice)
                  if (!choice.display_comments) {
                    updateQuestionAnswer(ques, choice, choice.choice_text)
                  }
                }}
              />
              <label
                style={{ color: '#232D42' }}
                className="form-check-label"
                htmlFor={`customRadio-outlinecolor1-${index}`}
              >
                {choice.choice_text}
              </label>
            </div>
          ))}


        </div>
      </Col>
    )
  }


  //     return (
  //         <div >
  //           {/* { console.log(currentQuestion)} */}
  //              {questionsRef && currentQuestion.length > 0 && currentQuestion.map((question, index) => (
  //               // console.log(question)
  //               <div key={question.question_id} className='col-lg-12'>
  //                 <div className='col-lg-12'>
  //               <Card key={question.question_id} className='col-lg-6'>
  //                     <CardHeader style={{ cursor: 'pointer', padding: '20px 10px' }}  onClick={() => toggle()}>
  //                         <h5 className="mb-2" style={{fontSize : 12}}>
  //                             {question.question_text}
  //                         </h5>
  //                         <h6 style={{ marginLeft: 20 }}><div dangerouslySetInnerHTML={{__html: question.question_sub_text}} /></h6>
  //                     </CardHeader>


  //                     {/* <Collapse isOpen={true}> */}
  //                         <CardBody>
  //                         {renderQuestionType(question)}

  //                     {answerText && question == answerQuestion &&  <div style={{display : 'grid'}}><div className="input-wrapper mb-3 mt-3" style={{display : commentsVisible && currentChoice.display_comments ? '' : 'none'}}>
  //       <Label className={currentChoice.comments_required ? "form-label required" : "form-label"} style={{marginBottom :0}}>Add Comments</Label>
  //       <Input
  //         name="key"
  //         type="textarea"
  //         rows={5}
  //         onChange={(text)=>{
  //           setCommentsText(text.target.value)
  //         //  updateQuestionComments(question, text)
  //         }}  
  //         placeholder="" />
  //       </div>
  //       <button style={{ margin : 'auto'}} className='btn btn-primary' onClick={() => {
  //                                         updateQuestionAnswer(question)
  //                                         // setCurrentQuestion([...currentQuestion, questionsRef[nextQuestionIndex]])
  //                                         // toggle(nextQuestionIndex);

  //                                     }}>
  //                                        <i className="bx bx-save"></i> Save

  //                                     </button>
  // </div>
  // }
  //                             {/* {question.choice_data.map((choice) => (
  //                                 <div key={choice.choice_id} className='mb-4'>
  //                                     <button style={{ width: '100%' }} className='btn btn-outline-primary' onClick={() => {
  //                                         const nextQuestionIndex = questions.findIndex(q => q.question_id === choice.next_question);
  //                                         toggle(nextQuestionIndex);
  //                                     }}>
  //                                         {choice.choice_text}

  //                                     </button>
  //                                 </div>
  //                             ))} */}
  //                             {/* Render sub-questions that have this question as their parent */}
  //                             {/* {questions.filter(q => q.parent_content_item === question.question_id).map((subQuestion, index2) => (
  //                                 <div key={subQuestion.question_id} style={{ marginTop: '20px' }}>

  //                                       <Card key={question.question_id}>
  //                                         <CardHeader style={{ cursor: 'pointer', padding: '15px 10px' }} onClick={() => toggle(index)}>
  //                                         <h6 className="mb-2">
  //                                            {subQuestion.question_text}
  //                                         </h6>
  //                                         <h6 style={{ marginLeft: 20 }}>{question.question_sub_text}</h6>
  //                                     </CardHeader>
  //                                   <Collapse isOpen={activeIndex == index} >
  //                                     {renderQuestionType(subQuestion)}
  //                                     </Collapse>
  //                                     </Card>
  //                                 </div>

  //                             ))} */}
  //                         </CardBody>
  //                     {/* </Collapse> */}


  //                 </Card>
  //                 </div>
  //                 <div className='col-lg-12'>
  //                  <Card className='col-lg-6' style={{display : question.answer_text == '' ? 'none' : '', float : 'right', background : '#369BFF', color : '#fff'}}>



  //                  {/* <Collapse isOpen={true}> */}
  //                      <CardBody>
  //                     <h5>{question.answer_text}</h5>

  //                  {question.answer_text &&  <div style={{display : 'grid'}}><div className="input-wrapper mb-3 mt-3" >
  //    <Label style={{marginBottom :0}}>Comments:</Label>
  //    {/* <Input
  //    disabled
  //      name="key"
  //      type="textarea"
  //      rows={5}
  //      value={question.ans_comments}
  //      placeholder="" /> */}
  //      <p>
  //      {question.ans_comments}
  //      </p>
  //    </div>

  // </div>
  // }

  //                      </CardBody>

  //              </Card>
  //              </div>
  //              </div>
  //                 ))} 
  //                             {/* {console.log(questions)} */}
  //                 {questionsRef.length >0 && currentQuestion == null ? 
  //                       <div>
  //                       <h5>Thank you!</h5>
  //                     </div>   
  //               : ''}
  //             {/* ))} */}
  //         </div>
  //     );


  return (
    <div>
      {currentQuestion != null && questionsRef && currentQuestion.length > 0 && type == 'add' ? currentQuestion.map((question, index) => (
        <div key={question.question_id} className='row' style={{ marginBottom: '20px' }}>
          <div className='col-lg-6'>
            <Card className='col-lg-12'>
              <CardHeader className='cardheader002' onClick={() => toggle()}>
                <h5 className="mb-2 question-02">
                  {question.question_text}
                </h5>
                <h6 style={{ marginLeft: 20 }}><div dangerouslySetInnerHTML={{ __html: question.question_sub_text }} /></h6>
              </CardHeader>


              {/* <Collapse isOpen={true}> */}
              <CardBody className='butn-Controller'>
                {renderQuestionType(question)}

                {answerText && question == answerQuestion && <div style={{ display: 'grid' }}>
                  <div className="input-wrapper mb-3 mt-3" style={{ display: currentChoice.choice_text == answerText && question == answerQuestion && currentChoice.display_comments ? '' : 'none' }}>
                    <Label className={currentChoice.choice_text == answerText && question == answerQuestion && currentChoice.comments_required ? "form-label required" : "form-label"} style={{ marginBottom: 0 }}>Add Comments</Label>
                    <Input
                      name="key"
                      type="textarea"
                      rows={5}
                      onChange={(text) => {
                        setCommentsText(text.target.value)
                        //  updateQuestionComments(question, text)
                      }}
                      placeholder="" />
                  </div>
                  <button style={{ margin: 'auto', display: currentChoice.choice_text == answerText && question == answerQuestion && currentChoice.display_comments ? '' : 'none' }} className='btn saveButton' onClick={() => {
                    updateQuestionAnswer(question, currentChoice, answerText)
                    // setCurrentQuestion([...currentQuestion, questionsRef[nextQuestionIndex]])
                    // toggle(nextQuestionIndex);

                  }}>
                    <i className="bx bx-save"></i> Save

                  </button>
                </div>
                }
                {/* {question.choice_data.map((choice) => (
                                <div key={choice.choice_id} className='mb-4'>
                                    <button style={{ width: '100%' }} className='btn btn-outline-primary' onClick={() => {
                                        const nextQuestionIndex = questions.findIndex(q => q.question_id === choice.next_question);
                                        toggle(nextQuestionIndex);
                                    }}>
                                        {choice.choice_text}
                                        
                                    </button>
                                </div>
                            ))} */}
                {/* Render sub-questions that have this question as their parent */}
                {/* {questions.filter(q => q.parent_content_item === question.question_id).map((subQuestion, index2) => (
                                <div key={subQuestion.question_id} style={{ marginTop: '20px' }}>
                                      
                                      <Card key={question.question_id}>
                                        <CardHeader style={{ cursor: 'pointer', padding: '15px 10px' }} onClick={() => toggle(index)}>
                                        <h6 className="mb-2">
                                           {subQuestion.question_text}
                                        </h6>
                                        <h6 style={{ marginLeft: 20 }}>{question.question_sub_text}</h6>
                                    </CardHeader>
                                  <Collapse isOpen={activeIndex == index} >
                                    {renderQuestionType(subQuestion)}
                                    </Collapse>
                                    </Card>
                                </div>
                            
                            ))} */}
              </CardBody>
              {/* </Collapse> */}


            </Card>
          </div>
          <div className='col-lg-12'>
            <Card className='col-lg-6' style={{ display: question.answer_text ? '' : 'none', float: 'right', background: '#369BFF', color: '#fff' }}>

              {/* <Collapse isOpen={true}> */}
              <CardBody>
                <h5>{question.answer_text}</h5>

                {question.answer_text && <div style={{ display: 'grid' }}>
                  <Label style={{ marginBottom: 0 }}>Comments:</Label>
                  {/* <Input
   disabled
     name="key"
     type="textarea"
     rows={5}
     value={question.ans_comments}
     placeholder="" /> */}
                  <p>
                    {question.ans_comments}
                  </p>


                </div>
                }

              </CardBody>
            </Card>

            <Card className='col-lg-6' style={{ display: question.content_item_type == 'Form' && question.data_saved == true ? '' : 'none', float: 'right', background: '#369BFF', color: '#fff' }}>

              {/* <Collapse isOpen={true}> */}
              <CardBody>
                {question.choice_data.map((ch, indx)=>(
                  <div key={indx}>

                    <Label>{ch.choice_text}</Label>
                    {ch.ans_item_text && <p>{ch.ans_item_text}</p>}
                  {ch.ans_item_text && <div style={{ display: 'grid' }}>
                    <Label style={{ marginBottom: 0 }}>Comments:</Label>
                    {/* <Input
                  disabled
                  name="key"
                  type="textarea"
                  rows={5}
                  value={question.ans_comments}
                  placeholder="" /> */}
                    <p>
                      {ch.ans_item_comments}
                    </p>


                  </div>
                  }

                  </div>
                ))}
              

              </CardBody>
            </Card>
          </div>
        </div>
      )) : <div>
         {/* {questions.length > 0 && (currentQuestion == null || childQuestions == null) ? */}
        <div>

          {/* <h5>Thank you!</h5> */}
          {/* <button className='btn btn-primary'
          onClick={(e)=>{
            e.preventDefault()
          }}
          >
            View Details
            </button> */}
{console.log(viewQuestionData)}
{viewQuestionData.length > 0 && type == 'add' && 
viewQuestionData.map((ques,index)=>(
  <div key={index} style={{marginBottom : 30,display : ques.answer_text == '' ? 'none' : ''}}>
  <h6 className='question-02'>
 Question {ques.sort_no}. {ques.question_text}
</h6>
<div style={{display: 'flex', alignItems: 'center'}}>
  <div style={{fontWeight : 450, marginRight : 10}}>Ans.</div> {renderAnswerType(ques)}
</div>
</div>
))
}

{ansData.length > 0 && type == 'alter' &&
ansData.map((ques,index)=>(
  <div key={index} style={{marginBottom : 30,display : ques.answer_text == '' ? 'none' : ''}}>
  <h6 className='question-02'>
 Question {ques.sort_no}. {ques.question_text}
</h6>
<div style={{display: 'flex', alignItems: 'center'}}>
  <div style={{fontWeight : 450, marginRight : 10}}>Ans.</div> {renderAnswerType(ques)}
</div>
</div>
))
}

        </div>
        {/* : ''} */}
      </div>}
      {/* {questionsRef.length > 0 || currentQuestion === null && (
              <div>
                  <h5>Thank you!</h5>
              </div>
          )} */}
    </div>
  );
};

export default Chatbot;
