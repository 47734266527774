import { authUser, sessionExpiration, sessionStorage } from "helpers/dataConfig";
import React, {useEffect, useState} from "react";
import { Navigate } from "react-router-dom";
import { Auth } from 'aws-amplify';
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import {
  getUserPersonalDetails,
  getUserPersonalDetails as onGetUserPersonalDetails
} from "store/actions"; 
import Loader from "components/Common/Loader";

const Authmiddleware = (props) => {

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState("");

  const { error, loading } = useSelector(state => ({
    error: state.Login.error,
    loading : state.Dashboard.loading
  }));

  useEffect(()=>{

    // console.log(process.env.REACT_APP_SESSION_EXP_LIMIT)
    // refreshToken()
    // refreshToken()
    // getUserDetails()

  }, [dispatch])

  useEffect(() => {
    setIsLoading(loading)
}, [loading]);

  const getUserDetails = () =>{
    // console.log('in user details')
  
    // dispatch(onGetUserPersonalDetails());
  }

  const { userPersonalData } = useSelector(state => ({
    userPersonalData: state.Login.userPersonalData,

  }));

  useEffect(() => {
   
      if(userPersonalData.user_role_tsp != undefined){

      setUserData(userPersonalData);
  

    }else{
      // console.log(props)
      dispatch(getUserPersonalDetails(dispatch))
    }
  // }
 
  }, [dispatch, userPersonalData]);

  useEffect(() => {

    // console.log(obj.fld_companyid)
    if(userPersonalData.fld_companyid != undefined){
      // console.log(userPersonalData)
      // console.log(localStorage.getItem(authUser))
    setUserData(userPersonalData)
    }
  }, [userPersonalData]);


  const refreshToken = async() =>{

      const userSession = JSON.parse(localStorage.getItem(sessionStorage))

    console.log(userSession.session.accessToken)

    const tokenExpiration = new Date(
      userSession.session.accessToken.payload.exp * 1000 // Convert expiration time to milliseconds
    );

    // Compare the token expiration with the current time
    const isTokenExpired = tokenExpiration < new Date();

    // console.log(isTokenExpired)

    if(isTokenExpired){
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        const currentSession = currentUser.signInUserSession;
        currentUser.refreshSession(currentSession.refreshToken, (err, session) => {
          // do something with the new session
          const sessionVal = {
            session : session
          }
          
          // localStorage.setItem(sessionStorage,JSON.stringify(sessionVal))

          const sessionExp = {
            sessionStartDate : moment().format(),
            sessionExpiryDays : 2
          }
          // localStorage.setItem(sessionExpiration, JSON.stringify(sessionExp))
        });
      } catch (e) {
        // whatever
      }
    }
    
  }

  const isTokenExpired = () =>{
      
    const exp = JSON.parse(localStorage.getItem(sessionExpiration))
    if(moment(exp.sessionStartDate).isSameOrBefore(moment().format())){
      return false
    }else{
      return true
    }

  }

  if (!localStorage.getItem(authUser) || !isTokenExpired) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }
  return (<React.Fragment>
    {userData && props.children}
    <Loader isLoading={isLoading} />
  </React.Fragment>);
};

export default Authmiddleware;
